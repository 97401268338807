<template lang="pug">
include ../../../configs/template
div.pa-4.mt-1
  +select('isContinue','typeStatements','typeApplication','nameLang')(item-value="value" @change="checkTypeSQC").pa-2
  TableV2(
    :actions="actions"
    :headers="headers"
    :isLoading="isLoading"
    :items="items.results"
    :currentPage="items.current"
    :pageCount="items.count"
    :querySearch="getLinkStatementSQC"
  )
</template>

<script>
import { mapActions, mapState } from 'vuex'
export default {
  name: 'TableSqc',
  props: {
    status: { type: Number, default: 24 }
  },
  data () {
    return {
      isContinue: null,
      headers: [
        { value: 'sailorFullName', text: this.$t('sailorName'), sortable: false },
        { value: 'number_document', text: this.$t('docNumber'), sortable: false },
        { value: 'branch_office.name_ukr', text: this.$t('affiliate'), sortable: false },
        { value: 'event', text: this.$t('actions'), align: 'center', sortable: false }
      ],
      actions: [
        {
          id: 1,
          to: ({ sailor, id }) => ({ name: 'sqc-statements-info', params: { id: sailor.id, documentID: id }, query: { ...this.$route.query } }),
          color: 'blue',
          name: 'mdi-arrow-right',
          tooltip: 'tooltip.go',
          buttonOptions: { xSmall: false, icon: true }
        }
      ],
      typeStatements: [
        { name_ukr: 'Всі', value: null },
        { name_ukr: 'ДКК іспит', value: '0,1,2' },
        { name_ukr: 'ДКК стаж', value: '3' },
        { name_ukr: 'Іспит CES 6.0', value: true }
      ]
    }
  },
  computed: {
    ...mapState({
      nameLang: state => state.main.lang === 'en' ? 'name_eng' : 'name_ukr',
      items: state => state.statement.listSqc,
      isLoading: state => state.statement.isLoading
    })
  },
  watch: {
    '$route.name' () {
      this.checkTypeSQC()
    }
  },
  methods: {
    ...mapActions(['getLinkStatementSQC']),
    checkTypeSQC (value) {
      let params = { ...this.$route.query }
      if (typeof value === 'boolean') params.ces_exam = value
      else if (value) params.is_continue = value
      this.getLinkStatementSQC(params)
    }
  }
}
</script>
